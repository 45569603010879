import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { BsFillBookmarkCheckFill, BsFillBookmarkXFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import api from './api';

const Trackers = ({ }) => {
    const [trackers, setTrackers] = useState(null);

    const deleteTracker = idToRemove => {
        if (window.confirm('Are you sure? This can\'t be undone!')) {
            api.deletee('/trackers/' + idToRemove).then(() => {
                setTrackers(old => old.filter(t => t.tracker.book_id !== idToRemove));
            });
        }
    };

    const updateProgress = bookId => {
        let page = prompt('Enter page number:');
        try {
            if (page && page.length) {
                page = parseInt(page);

                if (page) {
                    if (page < 1) {
                        page = 1;
                    }

                    api.post('/trackers/' + bookId + '/update', { page }).then(r => {
                        setTrackers(old => old.map(t => t.tracker.book_id === r.data.book_id ? { ...t, tracker: r.data } : t));
                    });
                }
            }
        } catch (e) { console.error(e) }
    };

    useEffect(() => {
        api.get('/trackers').then(r => setTrackers(r.data));
    }, []);

    if (!trackers) {
        return (<>
            <h1>Trackers</h1>

            <Container className="p-lg-5 p-2 pt-3 mb-4 bg-light rounded-3">
                <Spinner />
            </Container>
        </>);
    }

    return (<>
        <h1>Trackers</h1>

        <Container className="p-lg-5 p-2 pt-3 mb-4 bg-light rounded-3">
            <Row>
                {trackers.length ? trackers.map(({ tracker, per_day }) =>
                    <TrackerCard key={tracker.book_id}
                        tracker={tracker}
                        perDay={per_day}
                        onDelete={() => deleteTracker(tracker.book_id)}
                        onUpdate={() => updateProgress(tracker.book_id)} />
                ) : <p>You currently have no trackers. Why not <Link to="/">create one</Link>? :)</p>}
            </Row>
        </Container>
    </>);
}

const TrackerCard = ({ tracker, perDay, onDelete, onUpdate }) => {
    const pagesPerDay = perDay.length ? perDay.map(day => day.pages_read).reduce((a, b) => a + b, 0) / perDay.length : 0;
    const daysLeft = pagesPerDay > 0 ? ((tracker.pages - tracker.page) / pagesPerDay).toFixed() : null;

    return (<>
        <Col xs={12} md={6} style={{ marginBottom: 12 }}>
            <Card style={{ minHeight: 300, height: '100%' }}>
                <Card.Header as="span">
                    <span style={{ float: 'right' }}> <Button onClick={onDelete} variant="secondary" size='sm' style={{ marginTop: 2 }}><BsFillBookmarkXFill /> Delete</Button></span>
                    <h6 style={{ marginBottom: 0 }}>{tracker.title}</h6>
                    <div style={{ fontSize: 12, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{tracker.subtitle}</div>
                </Card.Header>

                <Card.Body>
                    <Card.Text style={{ marginTop: 8, fontSize: 14 }}>
                        <img src={'https://books.google.com/books/content?id=' + tracker.book_id + '&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api'} style={{ float: 'left', paddingRight: 8 }} />

                        {perDay.length ?
                            <>
                                {perDay.map(day => <span key={day.date}>{day.date}: you read {day.pages_read} pages.<br /></span>)}

                                <hr />
                                <p>On average, you've read <b>{pagesPerDay}</b> pages per <i>active</i> day.<br />It would take, at this pace, <b>&plusmn;{daysLeft} days</b> to complete the book.</p>
                            </>
                            : <span className="text-muted">You have not yet tracked any progress.</span>}
                    </Card.Text>
                </Card.Body>

                <div style={{ width: '100%', height: 16, backgroundColor: 'lightgray' }}>
                    <div style={{ display: 'absolute', backgroundColor: 'green', width: (tracker.page / tracker.pages * 100) + '%', height: 16, color: 'white', fontSize: 11, textAlign: 'center' }}>{(tracker.page / tracker.pages * 100).toFixed(1)}%</div>
                </div>

                <Card.Footer className="text-muted" style={{ fontSize: 12 }}>
                    <span style={{ float: 'right' }}> <Button onClick={onUpdate} variant="primary" size='sm' style={{ marginTop: 2 }}><BsFillBookmarkCheckFill /> Update progress</Button></span>
                    <span>{tracker.page} / {tracker.pages}<br /><b>pages</b></span>
                </Card.Footer>
            </Card>
        </Col>
    </>)
}

export default Trackers;
