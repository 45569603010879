import * as firebaseui from "firebaseui";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { store, auth } from "./store";

const LoginModal = () => {
    const user = useSelector(state => state.auth.userInfo);
    const loginOpen = useSelector(state => state.auth.loginOpen);

    useEffect(() => {
        if (!loginOpen) {
            return null;
        }

        const ui = new firebaseui.auth.AuthUI(getAuth());

        ui.start('#firebaseui-auth-container', {
            signInOptions: [
                GoogleAuthProvider.PROVIDER_ID,
            ],
            signInFlow: 'popup',
            callbacks: {
                // Redirection not needed, so return false.
                signInSuccessWithAuthResult: (result, url) => { return false },
            }
        });

        // Cleanup fn:
        return () => { ui.delete() };
    }, [loginOpen]);

    return (<>
        <Modal show={loginOpen && !user} onHide={() => store.dispatch(auth.actions.closeLogin())}>
            <Modal.Header closeButton>
                <Modal.Title>Sign In / Create Account</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Simply sign in with your Google account. If you don't have an account yet, it'll create one for you automatically.</p>

                <hr />
                <div id="firebaseui-auth-container"></div>
            </Modal.Body>
        </Modal>
    </>);
}

export default LoginModal;
