import { createSlice } from '@reduxjs/toolkit';

const auth = createSlice({
    name: 'auth',
    initialState: {
        userInfo: null,
        token: null,
        loaded: false,
        loginOpen: false,
        value: 0,
    },
    reducers: {
        authReady: (state, { payload: { userInfo, token } }) => {
            if (userInfo) {
                state.userInfo = userInfo;
                state.token = token;
            } else {
                state.token = null;
                state.userInfo = null;
            }

            state.loaded = true;
        },
        openLogin: state => {
            state.loginOpen = true;
        },
        closeLogin: state => {
            state.loginOpen = false;
        },
        incremented: state => {
            state.value += 1
        },
        decremented: state => {
            state.value -= 1
        }
    }
});

export default auth;
