import { getAuth } from "firebase/auth";
import { Navbar, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { store, auth } from "./store";

const SignInButton = () => {
    const user = useSelector(s => s.auth.userInfo);

    if (!user) {
        return (
            <>
                <Button variant="primary" onClick={() => store.dispatch(auth.actions.openLogin())}>Sign In</Button>
                <LoginModal />
            </>
        );
    }

    return (<>
        <Navbar.Text>
            <div>{user.name}</div><div style={{ marginTop: -10 }}><a onClick={() => getAuth().signOut()} href="#" style={{ fontSize: 11 }}>Sign Out</a></div>
        </Navbar.Text>
    </>);
}

const RequireSignin = ({ children }) => {
    const user = useSelector(s => s.auth.userInfo);
    return user ? children : <Navigate to="/" />
}

export { RequireSignin, SignInButton };
